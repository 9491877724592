import Footer from 'components/Domain/Footer';
import { motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';
import cssGlobal from 'styles/global.scss';
import css from 'styles/index.scss';

const Home = ({ top = 0 }) => {
  return (
    <motion.div
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      transition={{
        duration: 0.66
      }}
      variants={{
        pageInitial: {
          top: '100vh',
          opacity: 0,
        },
        pageAnimate: {
          top: 0,
          opacity: 1,
        },
        pageExit: {
          top: '-100vh',
          opacity: 0,
        }
      }}
      className={[cssGlobal.Page, css.Page, css.Home].join(' ')}
      style={{
        minHeight: `calc( 100vh - ${top}px)`
      }}
    >
      <div
        className={[cssGlobal.Content, css.Content].join(' ')}
      >
        <div
          className={css.Body}
        >
          <h1>
            Bienvenue
            <br/>chez SNCF !
          </h1>
          <p>
            Vous venez d’intégrer nos équipes et nous sommes très
            <br/> heureux de vous accueillir parmi nous.
          </p>
          <p>
            Votre arrivée dans l’entreprise est un moment clé pour
            <br/> vous et pour nous. <br className={css.Conditional}/> C’est la raison pour laquelle nous
            <br/> vous proposons ce module pour vous aider à faire
            <br/> connaissance avec l’entreprise et à répondre à vos
            <br/> interrogations.
          </p>
          <p>
            Nous vous invitons également à suivre le parcours
            <br/> <Link
            href="https://monacademie.sncf.fr/session/5eecceca0654237a8242674b/home"
          >
            <a
              target={'_blank'}
            >
              Bienvenue à bord
            </a>
          </Link> pour découvrir le fonctionnement du
            <br/> monde ferroviaire.
          </p>
          <Link href="/sommaire">
            <a
              className={css.Button}
            >
              Accédez au sommaire
            </a>
          </Link>
        </div>
      </div>
      <Footer
        className={css.Footer}
      />
    </motion.div>
  );
};

export default Home;
